import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  ChevronDown,
  ChevronLeft,
  ChevronUp,
  CreditCard,
  File,
  Link,
  List,
  Mail,
  Repeat,
  Tag,
} from 'react-feather';

const Features = ({ setDocsView, currentUser }) => {
  const style = currentUser
    ? 'h-screen mx-auto max-w-2xl flex flex-col items-start gap-4'
    : 'h-screen mx-auto max-w-2xl flex flex-col items-start mt-24 gap-4';

  const [openPay, setOpenPay] = useState(false);
  const [openMor, setOpenMor] = useState(false);
  const [openVar, setOpenVar] = useState(false);
  const [openDis, setOpenDis] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [openRe, setOpenRe] = useState(false);
  const [openLin, setOpenLin] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return isMobile ? (
    <div className="h-screen mx-auto w-full p-4 flex flex-col items-start mt-14 gap-4">
      <div className="flex flex-col items-end w-full gap-4">
        <div className="flex flex-col items-start w-full border border-gray-200 p-2 bg-gray-50 rounded-md">
          <p className="text-sm text-stone-800">Features</p>
          <p className="text-xs text-stone-600">
            Checkout all the features we currently offer below. Click on a
            feature to read more about it.
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2 mt-1"
          >
            <ChevronLeft size={14} />
            Docs
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full">
        <button
          type="button"
          onClick={() => setOpenPay(!openPay)}
          className="w-full flex flex-col items-start text-left gap-4 border border-gray-200 bg-gray-50 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <CreditCard size={18} />
              <p className="text-xs text-stone-800">Secure Payments</p>
            </div>
            {openPay ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openPay ? (
            <p className="text-xs text-stone-600">
              All transactions are securely processed through Stripe, ensuring
              user safety. We never store sensitive payment information, keeping
              your data completely safe.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenMor(!openMor)}
          className="w-full flex flex-col items-start text-left gap-4 bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <File size={18} />
              <p className="text-xs text-stone-800">MOR</p>
            </div>
            {openMor ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openMor ? (
            <p className="text-xs text-stone-600">
              We are your Merchant of Record. Any complications with
              transactions are handled by us. This includes taxes, chargebacks,
              and refunds.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenVar(!openVar)}
          className="w-full flex flex-col items-start text-left gap-4 bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <List size={18} />
              <p className="text-xs text-stone-800">Item Variations</p>
            </div>
            {openVar ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openVar ? (
            <p className="text-xs text-stone-600">
              Add variations to your items to offer different versions. This can
              be used to create a better customer experience. Ex: Pro, Basic..
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenDis(!openDis)}
          className="w-full flex flex-col items-start text-left gap-4 bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Tag size={18} />
              <p className="text-xs text-stone-800">Item Discounts</p>
            </div>
            {openDis ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openDis ? (
            <p className="text-xs text-stone-600">
              Easily create and offer discounts on your items to attract more
              customers.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenRe(!openRe)}
          className="w-full flex flex-col items-start text-left gap-4 bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Repeat size={18} />
              <p className="text-xs text-stone-800">Refund Policies</p>
            </div>
            {openRe ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openRe ? (
            <p className="text-xs text-stone-600">
              Easily define refund policies for your items so your customers
              know how and when to request a refund if allowed.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenDel(!openDel)}
          className="w-full flex flex-col text-left gap-4 bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Mail size={18} />
              <p className="text-xs text-stone-800">Delivery</p>
            </div>
            {openDel ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openDel ? (
            <p className="text-xs text-stone-600">
              All orders and project links are automatically delivered after a
              purchase. Customers receive an email with all order details and a
              link to view in the browser.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenLin(!openLin)}
          className="w-full flex flex-col text-left gap-4 bg-gray-50 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Link size={18} />
              <p className="text-xs text-stone-800">Duplicate & Remix Links</p>
            </div>
            {openLin ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openLin ? (
            <p className="text-xs text-stone-600">
              We support the sale of Duplicate Links for Notion projects and
              Remix Links for Framer projects.
            </p>
          ) : (
            ''
          )}
        </button>
        <div className="w-full flex items-center justify-center bg-gray-50 border border-gray-200 rounded-md p-2">
          <p className="text-xs text-stone-600">More coming soon</p>
        </div>
      </div>
    </div>
  ) : (
    <div className={style}>
      <div className="flex justify-between items-end w-full">
        <div className="flex flex-col items-start w-full border border-gray-200 p-2 rounded-md bg-gray-50">
          <p className="text-sm text-stone-800">Features</p>
          <p className="text-xs text-stone-600">
            Checkout all the features we currently offer below. Click on a
            feature to read more about it.
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center  gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2 mt-1"
          >
            <ChevronLeft size={14} />
            Docs
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full">
        <button
          type="button"
          onClick={() => setOpenPay(!openPay)}
          className="w-full flex flex-col bg-gray-50 items-start text-left gap-4 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <CreditCard size={18} />
              <p className="text-xs text-stone-800">Secure Payments</p>
            </div>
            {openPay ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openPay ? (
            <p className="text-xs text-stone-600">
              All transactions are securely processed through Stripe, ensuring
              user safety. We never store sensitive payment information, keeping
              your data completely safe.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenMor(!openMor)}
          className="w-full flex flex-col bg-gray-50 items-start text-left gap-4 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <File size={18} />
              <p className="text-xs text-stone-800">MOR</p>
            </div>
            {openMor ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openMor ? (
            <p className="text-xs text-stone-600">
              We are your Merchant of Record. Any complications with
              transactions are handled by us. This includes taxes, chargebacks,
              and refunds.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenVar(!openVar)}
          className="w-full flex flex-col bg-gray-50 items-start text-left gap-4 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <List size={18} />
              <p className="text-xs text-stone-800">Item Variations</p>
            </div>
            {openVar ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openVar ? (
            <p className="text-xs text-stone-600">
              Add variations to your items to offer different versions. This can
              be used to create a better customer experience. Ex: Pro, Basic..
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenDis(!openDis)}
          className="w-full flex flex-col bg-gray-50 items-start text-left gap-4 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Tag size={18} />
              <p className="text-xs text-stone-800">Item Discounts</p>
            </div>
            {openDis ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openDis ? (
            <p className="text-xs text-stone-600">
              Easily create and offer discounts on your items to attract more
              customers.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenRe(!openRe)}
          className="w-full flex flex-col bg-gray-50 items-start text-left gap-4 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Repeat size={18} />
              <p className="text-xs text-stone-800">Refund Policies</p>
            </div>
            {openRe ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openRe ? (
            <p className="text-xs text-stone-600">
              Easily define refund policies for your items so your customers
              know how and when to request a refund if allowed.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenDel(!openDel)}
          className="w-full flex flex-col bg-gray-50 text-left gap-4 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Mail size={18} />
              <p className="text-xs text-stone-800">Delivery</p>
            </div>
            {openDel ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openDel ? (
            <p className="text-xs text-stone-600">
              All orders and project links are automatically delivered after a
              purchase. Customers receive an email with all order details and a
              link to view in the browser.
            </p>
          ) : (
            ''
          )}
        </button>
        <button
          type="button"
          onClick={() => setOpenLin(!openLin)}
          className="w-full flex bg-gray-50 flex-col text-left gap-4 border border-gray-200 rounded-md p-2"
        >
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Link size={18} />
              <p className="text-xs text-stone-800">Duplicate & Remix Links</p>
            </div>
            {openLin ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
          {openLin ? (
            <p className="text-xs text-stone-600">
              We support the sale of Duplicate Links for Notion projects and
              Remix Links for Framer projects.
            </p>
          ) : (
            ''
          )}
        </button>
        <div className="w-full flex items-center justify-center bg-gray-50 border border-gray-200 rounded-md p-2">
          <p className="text-xs text-stone-600">More coming soon</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
