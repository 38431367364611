import React, { useRef, useState } from 'react';
import img1 from '../../media/newItemPic.png';
import img2 from '../../media/checkoutPic.png';
import img3 from '../../media/orderPic.png';
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import { ChevronRight, CornerRightDown, Maximize2 } from 'react-feather';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

const Screenshots = () => {
  const [view, setView] = useState('item');
  const [open, setOpen] = useState(false);
  const [openItem, setOpenItem] = useState(false);

  return isMobile ? (
    <div className="flex flex-col items-start gap-2 w-full">
      <p className="text-stone-600 text-sm flex items-center gap-1">
        Previews <CornerRightDown size={16} className="text-stone-800" />
      </p>
      <div className="flex items-center gap-2 p-1 border border-gray-200 rounded-md">
        <button
          type="button"
          onClick={(e) => setView('item')}
          className={`${
            view === 'item' ? 'bg-gray-200' : ''
          } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
        >
          New Item
        </button>
        <button
          type="button"
          onClick={(e) => setView('checkout')}
          className={`${
            view === 'checkout' ? 'bg-gray-200' : ''
          } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
        >
          Checkout
        </button>
        <button
          type="button"
          onClick={(e) => setView('order')}
          className={`${
            view === 'order' ? 'bg-gray-200' : ''
          } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
        >
          Order Detail
        </button>
      </div>

      {view === 'item' ? (
        <div className="flex flex-col gap-1">
          <div className="w-full relative p-2 bg-gray-50 border border-gray-200 rounded-md">
            <img src={img1} onClick={() => setOpen(!open)} />
            <div className="bg-gray-200 rounded-md absolute top-0 right-0 mr-2 mt-4 p-1">
              <Maximize2
                size={14}
                onClick={() => setOpen(!open)}
                className="text-stone-800 hover:cursor-pointer"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-xs text-stone-800">New Item Page</p>
            <p className="text-xs text-stone-600">
              Page for creating and listing new items
            </p>
          </div>

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[{ src: img1 }]}
            plugins={[Zoom]}
            animation={{ zoom: 500 }}
            zoom={{
              scrollToZoom: true,
              maxZoomPixelRatio: 2,
              zoomInMultiplier: 1.5,
            }}
            carousel={{ finite: true }}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        </div>
      ) : (
        ''
      )}

      {view === 'checkout' ? (
        <div className="flex flex-col gap-1">
          <div className="w-full relative p-2 bg-gray-50 border border-gray-200 rounded-md">
            <img src={img2} onClick={() => setOpen(!open)} />
            <div className="bg-gray-200 rounded-md absolute top-0 right-0 mr-2 mt-4 p-1">
              <Maximize2
                size={14}
                onClick={() => setOpen(!open)}
                className="text-stone-800 hover:cursor-pointer"
              />
            </div>
          </div>

          <div className="flex flex-col">
            <p className="text-xs text-stone-800">Checkout Page</p>
            <p className="text-xs text-stone-600">
              What customers see when entering payment details for an order
            </p>
          </div>

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[{ src: img2 }]}
            plugins={[Zoom]}
            animation={{ zoom: 500 }}
            zoom={{
              scrollToZoom: true,
              maxZoomPixelRatio: 2,
              zoomInMultiplier: 1.5,
            }}
            carousel={{ finite: true }}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        </div>
      ) : (
        ''
      )}

      {view === 'order' ? (
        <div className="flex flex-col gap-1">
          <div className="w-full relative p-2 bg-gray-50 border border-gray-200 rounded-md">
            <img src={img3} onClick={() => setOpen(!open)} />
            <div className="bg-gray-200 rounded-md absolute top-0 right-0 mr-2 mt-4 p-1">
              <Maximize2
                size={14}
                onClick={() => setOpen(!open)}
                className="text-stone-800 hover:cursor-pointer"
              />
            </div>
          </div>

          <div className="flex flex-col">
            <p className="text-xs text-stone-800">Order Detail Page</p>
            <p className="text-xs text-stone-600">
              What sellers see in their dashboard when viewing an order
            </p>
          </div>

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[{ src: img3 }]}
            plugins={[Zoom]}
            animation={{ zoom: 500 }}
            zoom={{
              scrollToZoom: true,
              maxZoomPixelRatio: 2,
              zoomInMultiplier: 1.5,
            }}
            carousel={{ finite: true }}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  ) : (
    <div className="flex flex-col items-start gap-2 w-8/12">
      <p className="text-stone-600 text-sm flex items-center gap-1">
        Previews <CornerRightDown size={16} className="text-stone-800" />
      </p>
      <div className="flex items-end justify-between w-full">
        <div className="flex items-center gap-2 p-1 border border-gray-200 rounded-md">
          <button
            type="button"
            onClick={(e) => setView('item')}
            className={`${
              view === 'item' ? 'bg-gray-200' : ''
            } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
          >
            New Item
          </button>
          <button
            type="button"
            onClick={(e) => setView('checkout')}
            className={`${
              view === 'checkout' ? 'bg-gray-200' : ''
            } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
          >
            Checkout
          </button>
          <button
            type="button"
            onClick={(e) => setView('order')}
            className={`${
              view === 'order' ? 'bg-gray-200' : ''
            } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
          >
            Order Detail
          </button>
        </div>
        <Link
          to="/docs"
          state={{ view: 'demos' }}
          className="flex items-center text-xs text-stone-800"
        >
          See tutorials <ChevronRight size={14} />
        </Link>
      </div>

      {view === 'item' ? (
        <div className="flex flex-col gap-1 w-full">
          <div className="w-full relative p-2 bg-gray-50 border border-gray-200 rounded-md">
            <img
              src={img1}
              onClick={() => setOpenItem(!openItem)}
              style={{ height: '400px' }}
              className="w-full"
            />
            <div className="bg-gray-200/75 hover:bg-gray-200 rounded-md absolute top-0 right-0 mr-2 mt-4 p-1">
              <Maximize2
                size={14}
                onClick={() => setOpenItem(!openItem)}
                className="text-stone-800 hover:cursor-pointer"
              />
            </div>
          </div>
          <div className="flex flex-col items-start">
            <p className="text-xs text-stone-800">New Item Page</p>
            <p className="text-xs text-stone-600">
              Page for creating and listing new items
            </p>
          </div>

          <Lightbox
            open={openItem}
            close={() => setOpenItem(false)}
            slides={[
              {
                type: 'image',
                src: img1,
                title: 'New Item Pic',
                description: 'Screenshot',
              },
            ]}
            plugins={[Zoom]}
            carousel={{ finite: true }}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
            animation={{ zoom: 500 }}
            zoom={{
              scrollToZoom: true,
              maxZoomPixelRatio: 2,
              zoomInMultiplier: 1.5,
              wheelZoomDistanceFactor: 300,
            }}
          />
        </div>
      ) : (
        ''
      )}

      {view === 'checkout' ? (
        <div className="flex flex-col gap-1 w-full">
          <div className="w-full relative p-2 bg-gray-50 border border-gray-200 rounded-md">
            <div className="w-full" style={{ height: '400px' }}>
              <img
                src={img2}
                onClick={() => setOpen(!open)}
                className="w-full h-full object-fill"
              />
            </div>
            <div className="bg-gray-200/75 hover:bg-gray-200 rounded-md absolute top-0 right-0 mr-2 mt-4 p-1">
              <Maximize2
                size={14}
                onClick={() => setOpen(!open)}
                className="text-stone-800 hover:cursor-pointer"
              />
            </div>
          </div>

          <div className="flex flex-col items-start">
            <p className="text-xs text-stone-800">Checkout Page</p>
            <p className="text-xs text-stone-600">
              What customers see when entering payment details for an order
            </p>
          </div>

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[{ src: img2 }]}
            plugins={[Zoom]}
            animation={{ zoom: 500 }}
            zoom={{
              scrollToZoom: true,
              maxZoomPixelRatio: 2,
              zoomInMultiplier: 1.5,
              wheelZoomDistanceFactor: 300,
            }}
            carousel={{ finite: true }}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        </div>
      ) : (
        ''
      )}

      {view === 'order' ? (
        <div className="flex flex-col gap-1 w-full">
          <div className="w-full relative p-2 bg-gray-50 border border-gray-200 rounded-md">
            <img
              src={img3}
              onClick={() => setOpen(!open)}
              style={{ height: '400px' }}
              className="w-full"
            />
            <div className="bg-gray-200/75 hover:bg-gray-200 rounded-md absolute top-0 right-0 mr-2 mt-4 p-1">
              <Maximize2
                size={14}
                onClick={() => setOpen(!open)}
                className="text-stone-800 hover:cursor-pointer"
              />
            </div>
          </div>

          <div className="flex flex-col">
            <p className="text-xs text-stone-800">Order Detail Page</p>
            <p className="text-xs text-stone-600">
              What sellers see in their dashboard when viewing an order
            </p>
          </div>

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[{ src: img3 }]}
            plugins={[Zoom]}
            animation={{ zoom: 500 }}
            zoom={{
              scrollToZoom: true,
              maxZoomPixelRatio: 2,
              zoomInMultiplier: 1.5,
              wheelZoomDistanceFactor: 300,
            }}
            carousel={{ finite: true }}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Screenshots;
