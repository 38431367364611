import React, { useState, useEffect, useRef } from 'react';
import { HiMenu } from 'react-icons/hi';
import { Map, Menu, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { Dropdown } from 'flowbite-react';

const MobileNavbar = ({ currentUser, handleLogout }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false); // Close the menu if clicked outside
      }
    };

    // Add event listener to detect clicks outside the menu
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return currentUser ? (
    <>
      <nav className="w-full h-16 border-b shadow bg-white fixed top-0 left-0 right-0 z-50">
        <div className="w-11/12 h-full mx-auto flex justify-between items-center">
          <div>
            <Link to="/dashboard" className="h-full flex gap-1">
              {/* <IoStorefrontOutline className='text-stone-800 font-bold text-xl' /> */}
              <Map size={18} />
              <p
                className="font-black text-stone-800 text-md"
                style={{ fontFamily: 'Inter, sans-serif' }}
              >
                Fruntt
              </p>
            </Link>
          </div>

          <div className="flex items-center h-full">
            <HiMenu className="text-2xl font-black" />
          </div>
        </div>
      </nav>
    </>
  ) : (
    <>
      <nav className="w-full bg-white fixed top-0 left-0 right-0 p-4 pb-0 z-50">
        <div className="p-2 mx-auto flex flex-col gap-1 border border-gray-200 rounded-md">
          <div className="w-full flex justify-between items-center">
            <div>
              <Link to="/" className="h-full flex gap-1">
                {/* <IoStorefrontOutline className='text-stone-800 font-bold text-xl' /> */}
                <Map size={18} />
                <p
                  className="font-black text-stone-800 text-md"
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </Link>
            </div>
            <Dropdown
              label=""
              renderTrigger={() => <Menu size={20} />}
              className="z-50 w-32 p-1"
            >
              <Link to="/docs" className="outline-none">
                <Dropdown.Item className="text-xs flex items-center justify-center text-stone-800 hover:border-gray-200 hover:outline-none outline-none w-full ring-0">
                  Docs
                </Dropdown.Item>
              </Link>

              <Link to="/pricing" className="outline-none">
                <Dropdown.Item className="text-xs text-stone-800 flex items-center justify-center w-full hover:border-gray-200 hover:outline-none outline-none ring-0">
                  Pricing
                </Dropdown.Item>
              </Link>

              <Link to="/signup" className="outline-none">
                <Dropdown.Item className="text-xs text-stone-800 flex items-center justify-center w-full hover:border-gray-200 hover:outline-none outline-none ring-0">
                  Sell
                </Dropdown.Item>
              </Link>

              <Link to="/login" className="outline-none">
                <Dropdown.Item className="text-xs text-stone-800 flex items-center justify-center bg-gray-200 p-1 rounded-md hover:outline-none outline-none ring-0">
                  Login
                </Dropdown.Item>
              </Link>
            </Dropdown>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MobileNavbar;
