import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Features from './Features';
import Email from './Email';
import Products from './Products';
import { Accordion, Badge, Spinner } from 'flowbite-react';
import {
  ChevronRight,
  CornerRightDown,
  ExternalLink,
  Plus,
  Truck,
} from 'react-feather';
import Screenshots from './Screenshots';

const Hero = ({ products, gettingProducts, gotProducts }) => {
  const [view, setView] = useState('info');

  const MobileDisplay = () => {
    switch (view) {
      case 'items':
        return gettingProducts ? (
          <div className="w-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <Products
            products={products}
            gettingProducts={gettingProducts}
            gotProducts={gotProducts}
          />
        );
      case 'pricing':
        return (
          <div className="flex flex-col items-end gap-2 w-full">
            <div className="w-full p-2 bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
              <p className="text-xs text-stone-600 flex items-center">
                No monthly fees, just a small amount taken from each sale
              </p>
            </div>
            <div className="flex items-center w-full gap-2">
              <div className="bg-gray-50 rounded-md border border-gray-200 flex flex-col items-center justify-center p-4 w-3/6">
                <p className="font-bold text-stone-800">$0.25</p>

                <p className="mt-1 text-xs text-stone-600 text-center">
                  fee on sales <span className="font-bold">under $5</span>
                </p>
              </div>
              <div className="bg-gray-50 rounded-md border border-gray-200 flex flex-col items-center justify-center p-4 w-3/6">
                <p className="font-bold text-stone-800">$1.00</p>

                <p className="mt-1 text-xs text-stone-600 text-center">
                  fee on sales <span className="font-bold">$5+</span>
                </p>
              </div>
            </div>
            <div className="w-full p-2 bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
              <p className="text-xs text-stone-600 flex items-center">
                with an additional fee of 2.9% + 30 ¢ / sale by{' '}
                <span className="underline underline-offset-2 ml-1">
                  <a href="https://stripe.com/pricing" target="_blank">
                    {' '}
                    Stripe
                  </a>
                </span>{' '}
              </p>
            </div>
            <Link
              to="/pricing"
              className="text-stone-800 flex items-center text-xs"
            >
              See pricing <ChevronRight size={14} />
            </Link>
          </div>
        );
      case 'coming':
        return (
          <div className="flex flex-col gap-4 p-4 border border-gray-200 bg-gray-50 rounded-md w-full">
            <p className="text-sm text-stone-800">What's on the way?</p>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Multiple Currencies</p>
              <p className="text-xs text-stone-600">
                List your items and view your dashboard in the currency of your
                choice
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Upsells</p>
              <p className="text-xs text-stone-600">
                Suggest other items to customers making a purchase
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Marketplace</p>
              <p className="text-xs text-stone-600">
                A place to browse all items from all creators
              </p>
            </div>
          </div>
        );
      case 'info':
        return (
          <Accordion collapseAll className="w-full">
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">
                  What is a duplicate link?
                </p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col gap-4">
                  <p className="text-stone-600 text-xs mb-2">
                    After you create something in Notion you can publish it.
                    Once published, you will have a "duplicate link" that allows
                    others to copy what you created into their own workspace.
                    This is what you share or sell.
                  </p>
                  <div className="flex flex-col items-start">
                    <p className="text-xs text-stone-600">Ex. link</p>
                    <button
                      className="text-xs text-stone-800 p-1 bg-gray-200 rounded-md"
                      disabled
                    >
                      carbonated-hill-6bc.notion.site/43be8a4d9
                    </button>
                  </div>
                  <a
                    href="https://www.notion.so"
                    className="text-stone-800 text-xs flex gap-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open Notion <ExternalLink size={14} />
                  </a>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">What is a remix link?</p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col gap-4">
                  <p className="text-stone-600 text-xs">
                    After creating a project in Framer, you can copy the "remix
                    link". The remix link allows others to copy the project into
                    their own workspace. This is what you share or sell.
                  </p>
                  <div className="flex flex-col items-start">
                    <p className="text-xs text-stone-600">Ex. link</p>
                    <button
                      className="text-xs text-stone-800 p-1 bg-gray-200 rounded-md"
                      disabled
                    >
                      framer.com/projects/new?duplicate=xrop2y
                    </button>
                  </div>
                  <a
                    href="https://framer.com"
                    className="text-stone-800 text-xs flex gap-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open Framer <ExternalLink size={14} />
                  </a>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">
                  How do I sell my project link?
                </p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col items-start gap-4">
                  <p className="text-xs text-stone-600">
                    1. Sign up or sign in to your Fruntt account.
                  </p>
                  <p className="text-xs text-stone-600">
                    2. Once signed in, click 'New +' in the side menu to create
                    a new item
                  </p>
                  <p className="text-xs text-stone-600">
                    3. Fill out all details required, including your duplicate
                    OR remix link.
                  </p>
                  <p className="text-xs text-stone-600">
                    Share your item using your fruntt.com/page_url/item_url link
                    wherever you'd like to allow customers to view and purchase.
                  </p>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">
                  How do I publish an item?
                </p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col items-start gap-4">
                  <p className="text-xs text-stone-600">
                    Publishing an item makes it visible on your public page for
                    customers to view and buy. Simply select 'Publish' before
                    saving your new or existing item.
                  </p>

                  <p className="text-xs text-stone-600">
                    When you first create an item and publish it, we have to
                    review it and approve it. We review all items within 24
                    hours and you are notified via email. If an item is
                    rejected, you will have suggested edits to make before you
                    submit again.
                  </p>

                  <p className="text-xs text-stone-600">
                    Once an item is approved, you can publish and unpublish it
                    without needing a review.
                  </p>

                  <div className="flex flex-col gap-1">
                    <p className="text-xs text-stone-600">Item statuses:</p>
                    <div className="flex items-center gap-1">
                      <Badge color="info">Draft</Badge>
                      <Badge color="success">Published</Badge>
                      <Badge color="warning">In Review</Badge>
                    </div>
                  </div>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">How do I get paid?</p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col items-start gap-4">
                  <p className="text-xs text-stone-600">
                    You have the option to connect a bank account or a Stripe
                    account.
                  </p>

                  <p className="text-xs text-stone-600">
                    If a bank account is connected, payouts occur only when you
                    have a positive balance. Depending on your account settings,
                    payouts will happen monthly or weekly.
                  </p>

                  <p className="text-xs text-stone-600">
                    If a Stripe account is connected, funds move directly to
                    your connected Stripe account immediately after a successful
                    sale.
                  </p>
                  <Link
                    to="/pricing"
                    className="text-stone-800 flex items-center text-xs"
                  >
                    See pricing
                    <ChevronRight size={14} />
                  </Link>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        );
      default:
        return;
    }
  };

  const Display = () => {
    switch (view) {
      case 'items':
        return gettingProducts ? (
          <div className="w-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <Products
            products={products}
            gettingProducts={gettingProducts}
            gotProducts={gotProducts}
          />
        );
      case 'pricing':
        return (
          <div className="w-full flex items-center justify-center">
            <div
              className="flex flex-col items-end gap-2"
              style={{ width: '450px' }}
            >
              <div className="w-full p-4 bg-gray-50 border border-gray-200 rounded-md flex items-center justify-center">
                <p className="text-xs text-stone-600 flex items-center text-center">
                  No monthly fees, just a small amount taken from each sale
                </p>
              </div>
              <div className="flex items-center w-full gap-2">
                <div className=" rounded-md border border-gray-200 flex flex-col items-center justify-center p-4 bg-gray-50 w-3/6">
                  <p className="font-bold text-stone-800">$0.25</p>

                  <p className="mt-1 text-xs text-stone-600 text-center">
                    fee on sales <span className="font-bold">under $5</span>
                  </p>
                </div>
                <div className=" rounded-md border border-gray-200 flex flex-col items-center justify-center p-4 bg-gray-50 w-3/6">
                  <p className="font-bold text-stone-800">$1.00</p>

                  <p className="mt-1 text-xs text-stone-600 text-center">
                    fee on sales <span className="font-bold">$5+</span>
                  </p>
                </div>
              </div>
              <div className="w-full p-4 bg-gray-50  border border-gray-200 rounded-md flex items-center justify-center">
                <p className="text-xs text-stone-600 flex items-center text-center">
                  with an additional fee of 2.9% + 30 ¢ / sale by{' '}
                  <span className="underline underline-offset-2 ml-1">
                    <a
                      href="https://stripe.com/pricing"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {' '}
                      Stripe
                    </a>
                  </span>{' '}
                </p>
              </div>
              <Link
                to="/pricing"
                className="text-stone-800 flex items-center text-xs"
              >
                See pricing
                <ChevronRight size={14} />
              </Link>
            </div>
          </div>
        );
      case 'coming':
        return (
          <div
            className="flex flex-col gap-4 p-4 bg-gray-50 border border-gray-200 rounded-md"
            style={{ width: '450px' }}
          >
            <p className="text-sm text-stone-800">What's on the way?</p>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Multiple Currencies</p>
              <p className="text-xs text-stone-600">
                List your items and view your dashboard in the currency of your
                choice
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Upsells</p>
              <p className="text-xs text-stone-600">
                Suggest other items to customers making a purchase
              </p>
            </div>
            <div className="flex flex-col items-start">
              <p className="text-xs text-stone-800">Marketplace</p>
              <p className="text-xs text-stone-600">
                A place to browse all items from all creators
              </p>
            </div>
          </div>
        );
      case 'info':
        return (
          <Accordion collapseAll style={{ width: '450px' }}>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">
                  What is a duplicate link?
                </p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col gap-4">
                  <p className="text-stone-600 text-xs mb-2">
                    After you create something in Notion you can publish it.
                    Once published, you will have a "duplicate link" that allows
                    others to copy what you created into their own workspace.
                    This is what you share or sell.
                  </p>
                  <div className="flex flex-col items-start">
                    <p className="text-xs text-stone-600">Ex. link</p>
                    <button
                      className="text-xs text-stone-800 p-1 bg-gray-200 rounded-md"
                      disabled
                    >
                      carbonated-hill-6bc.notion.site/43be8a4d9
                    </button>
                  </div>
                  <a
                    href="https://www.notion.so"
                    className="text-stone-800 text-xs flex gap-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open Notion <ExternalLink size={14} />
                  </a>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">What is a remix link?</p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col gap-4">
                  <p className="text-stone-600 text-xs">
                    After creating a project in Framer, you can copy the "remix
                    link". The remix link allows others to copy the project into
                    their own workspace. This is what you share or sell.
                  </p>
                  <div className="flex flex-col items-start">
                    <p className="text-xs text-stone-600">Ex. link</p>
                    <button
                      className="text-xs text-stone-800 p-1 bg-gray-200 rounded-md"
                      disabled
                    >
                      framer.com/projects/new?duplicate=xrop2yEDr5RWhR50BPJM
                    </button>
                  </div>
                  <a
                    href="https://framer.com"
                    className="text-stone-800 text-xs flex gap-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open Framer <ExternalLink size={14} />
                  </a>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">
                  How do I sell my project link?
                </p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col items-start gap-4">
                  <p className="text-xs text-stone-600">
                    1. Sign up or sign in to your Fruntt account.
                  </p>
                  <p className="text-xs text-stone-600">
                    2. Once signed in, click 'New +' in the side menu to create
                    a new item
                  </p>
                  <p className="text-xs text-stone-600">
                    3. Fill out all details required, including your duplicate
                    OR remix link.
                  </p>
                  <p className="text-xs text-stone-600">
                    Share your item using your fruntt.com/page_url/item_url link
                    wherever you'd like to allow customers to view and purchase.
                  </p>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">
                  How do I publish an item?
                </p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col items-start gap-4">
                  <p className="text-xs text-stone-600">
                    Publishing an item makes it visible on your public page for
                    customers to view and buy. Simply select 'Publish' before
                    saving your new or existing item.
                  </p>

                  <p className="text-xs text-stone-600">
                    When you first create an item and publish it, we have to
                    review it and approve it. We review all items within 24
                    hours and you are notified via email. If an item is
                    rejected, you will have suggested edits to make before you
                    submit again.
                  </p>

                  <p className="text-xs text-stone-600">
                    Once an item is approved, you can publish and unpublish it
                    without needing a review.
                  </p>

                  <div className="flex flex-col gap-1">
                    <p className="text-xs text-stone-600">Item statuses:</p>
                    <div className="flex items-center gap-1">
                      <Badge color="info">Draft</Badge>
                      <Badge color="success">Published</Badge>
                      <Badge color="warning">In Review</Badge>
                    </div>
                  </div>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
            <Accordion.Panel>
              <Accordion.Title>
                <p className="text-xs text-stone-800">How do I get paid?</p>
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex flex-col items-start gap-4">
                  <p className="text-xs text-stone-600">
                    You have the option to connect a bank account or a Stripe
                    account.
                  </p>

                  <p className="text-xs text-stone-600">
                    If a bank account is connected, payouts occur only when you
                    have a positive balance. Depending on your account settings,
                    payouts will happen monthly or weekly.
                  </p>

                  <p className="text-xs text-stone-600">
                    If a Stripe account is connected, funds move directly to
                    your connected Stripe account immediately after a successful
                    sale.
                  </p>
                  <Link
                    to="/pricing"
                    className="text-stone-800 flex items-center text-xs"
                  >
                    See pricing <ChevronRight size={14} />
                  </Link>
                </div>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        );
      default:
        return;
    }
  };

  return isMobile ? (
    <div className="flex flex-col gap-44 w-full mt-28 mb-44 p-4">
      <div className="flex flex-col">
        <div className="flex flex-col text-left w-full">
          <div className="flex flex-col items-center text-center gap-8">
            <p className="font-bold text-2xl text-stone-800">
              Quick and easy way to sell your project links
            </p>

            <p className="text-xs text-stone-600">
              Easily allow access to your templates, websites, designs, and more
              by selling your project links. Avoid high fees and complexity that
              comes with other platforms.
            </p>
            <div className="flex flex-col items-center gap-2">
              <div className="flex items-center gap-2">
                <p className="text-white bg-stone-800 rounded-md p-2 w-28 text-xs inline-block">
                  Duplicate Links
                </p>
                <Plus size={14} />
                <p className="text-white bg-stone-800 rounded-md p-2 w-28 text-xs inline-block">
                  Remix Links
                </p>
              </div>
              <p className="text-stone-800 text-xs">supported</p>
            </div>
          </div>
        </div>
      </div>
      <Features />
      <Screenshots />
      <div className="flex flex-col items-start gap-2 w-full">
        <p className="text-stone-600 text-sm flex items-center gap-1">
          Other <CornerRightDown size={16} className="text-stone-800" />
        </p>
        <div className="flex items-center gap-2 p-1 border border-gray-200 rounded-md">
          <button
            type="button"
            onClick={(e) => setView('info')}
            className={`${
              view === 'info' ? 'bg-gray-200' : ''
            } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
          >
            How
          </button>
          <button
            type="button"
            onClick={(e) => setView('items')}
            className={`${
              view === 'items' ? 'bg-gray-200' : ''
            } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
          >
            Items
          </button>
          <button
            type="button"
            onClick={(e) => setView('coming')}
            className={`${
              view === 'coming' ? 'bg-gray-200' : ''
            } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
          >
            Coming
          </button>
          <button
            type="button"
            onClick={(e) => setView('pricing')}
            className={`${
              view === 'pricing' ? 'bg-gray-200' : ''
            } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
          >
            Pricing
          </button>
        </div>
        <MobileDisplay />
      </div>
      {/* {gotProducts && (
        <Products
          products={products}
          gettingProducts={gettingProducts}
          gotProducts={gotProducts}
        />
      )} */}
      <Email />
    </div>
  ) : (
    <div className="flex flex-col items-center max-w-6xl mt-44 h-full gap-52">
      <div className="flex items-center justify-center gap-24 w-full">
        <div
          className="flex flex-col items-center text-center gap-8"
          style={{ width: '450px' }}
        >
          <p className="font-bold text-4xl text-stone-800">
            Quick and easy way to sell your project links
          </p>

          <p className="text-sm text-stone-600">
            Easily allow access to your templates, websites, designs, and more
            by selling your project links. Avoid high fees and complexity that
            comes with other platforms.
          </p>

          <div className="flex flex-col items-center gap-2">
            <div className="flex items-center gap-2">
              <p className="text-white bg-stone-800 rounded-md p-2 w-32 text-xs inline-block">
                Duplicate Links
              </p>
              <Plus size={16} />
              <p className="text-white bg-stone-800 rounded-md p-2 w-32 text-xs inline-block">
                Remix Links
              </p>
            </div>
            <p className="text-stone-800 text-xs">supported</p>
          </div>
        </div>
      </div>
      <div className="w-full flex items-start gap-10">
        <Screenshots />
        <Features />
      </div>

      <div className="flex flex-col items-center justify-center gap-2 w-full">
        <div className="flex flex-col items-start gap-2">
          <p className="text-stone-600 text-sm flex items-center gap-1">
            Other <CornerRightDown size={16} className="text-stone-800" />
          </p>
          <div className="flex items-center gap-2 p-1 border border-gray-200 rounded-md">
            <button
              type="button"
              onClick={(e) => setView('info')}
              className={`${
                view === 'info' ? 'bg-gray-200' : ''
              } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
            >
              How
            </button>
            <button
              type="button"
              onClick={(e) => setView('items')}
              className={`${
                view === 'items' ? 'bg-gray-200' : ''
              } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
            >
              Items
            </button>
            <button
              type="button"
              onClick={(e) => setView('coming')}
              className={`${
                view === 'coming' ? 'bg-gray-200' : ''
              } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
            >
              Coming
            </button>
            <button
              type="button"
              onClick={(e) => setView('pricing')}
              className={`${
                view === 'pricing' ? 'bg-gray-200' : ''
              } rounded-md text-xs p-1 pl-2 pr-2 hover:bg-gray-200`}
            >
              Pricing
            </button>
          </div>
        </div>
        <Display />
      </div>
      {/* {gotProducts && (
        <Products
          products={products}
          gettingProducts={gettingProducts}
          gotProducts={gotProducts}
        />
      )} */}
      <Email />
    </div>
  );
};

export default Hero;
